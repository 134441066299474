import "../Component.css";
import { Form, Button, Container } from "react-bootstrap";
import { useState } from "react";
import fire from "../../firebase";
import "firebase/firestore";
import firebase from "firebase/app";

export default function Enquiry() {

    const db = fire.firestore();

    const [nm,setNm] = useState('');
    const [ph,setPh] = useState('');
    const [em,setEm] = useState('');
    const [st,setSt] = useState('');
    const [ct,setCt] = useState('');
    const [eq,setEq] = useState('');
    const [sc,setSc] = useState('');

    const enquiry = (e) => {
        e.preventDefault();
        db.collection('Admission_Enquiry').add({
            Name : nm,
            Phone : ph,
            Email : em,
            State : st,
            City : ct,
            Qualification : eq,
            Course : sc,
            time : firebase.firestore.FieldValue.serverTimestamp()
        })
        window.alert('Your Form Has Been Submitted.');
        setNm('');
        setPh('');
        setEm('');
        setSt('');
        setCt('');
        setEq('');
        setSc('');
    }

    return (
        <Container className="">
            <form>
            <Form.Group controlId="exampleForm.ControlInput1" className="mt-3">
                <h2 className="mt-3 mb-3">Admission Enquiry</h2>
                <Form.Control type="text" className="mt-2" placeholder="Enter Name" value={nm} onChange={(e)=>setNm(e.target.value)} required />
                <Form.Control type="text" className="mt-2" placeholder="Enter Phone Number" value={ph} onChange={(e)=>setPh(e.target.value)} required />
                <Form.Control type="email" className="mt-2" placeholder="Enter Email Address" value={em} onChange={(e)=>setEm(e.target.value)} required />
                <Form.Control type="text" className="mt-2" placeholder="Enter State" value={st} onChange={(e)=>setSt(e.target.value)} required />
                <Form.Control type="text" className="mt-2" placeholder="Enter City" value={ct} onChange={(e)=>setCt(e.target.value)} required />
                <Form.Control type="text" className="mt-2" placeholder="Enter Qualification" value={eq} onChange={(e)=>setEq(e.target.value)} required />
                <Form.Control as="select" className="mt-2" value={sc} onChange={(e)=>setSc(e.target.value)} required>
                    <option>Select Course</option>
                    <option value="+2 Science">+2 Science</option>
                    <option value="DMLT">DMLT</option>
                    <option value="D.Pharma">D.Pharma</option>
                    <option value="ANM">ANM</option>
                    <option value="GNM">GNM</option>
                    <option value="B.Sc Nursing">B.Sc Nursing</option>
                    <option value="Hotel Management & Tourism">Hotel Management & Tourism</option>
                </Form.Control>
                <Button className="mt-2 bg-primary" style={{border: "none",fontWeight: "600"}} onClick={enquiry} disabled={!ph || !nm || !eq}>SUBMIT</Button>
            </Form.Group>
            </form>
        </Container>
    )
}