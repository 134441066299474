import { Container } from "react-bootstrap";
import "../../Component.css";
import shriram from "../../shriram.jpeg";
import lal from "../../lal.jpeg";
import yasoda from "../../yasoda.jpeg";
import sevenhill from "../../sevenhills.jpeg";
import reddy from "../../reddy.jpeg";
import apollo from "../../apollo.jpeg";

export default function Recruiter() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 text-center">
            <h2>OUR RECRUITERS</h2>
                <img width="100px" className="m-3" src={apollo} alt='our recruiters' />
                <img width="100px" className="m-3" src={lal} alt='our recruiters' />
                <img width="100px" className="m-3" src={reddy} alt='our recruiters' />
                <img width="100px" className="m-3" src={sevenhill} alt='our recruiters' />
                <img width="100px" className="m-3" src={shriram} alt='our recruiters' />
                <img width="100px" className="m-3" src={yasoda} alt='our recruiters' />
        </Container>
    )
}