import './App.css';
import Navs from "./Components/Navbar";
import Home from "./Components/Home";
import Admin from "./Components/TopNavbar/Admin";
import Enquiry from "./Components/TopNavbar/AdmissionEnquiry";
import Career from "./Components/TopNavbar/Career";
import Placement from "./Components/TopNavbar/Placement";
import Institute from "./Components/MainNavbar/About/Institute";
import Chairman from "./Components/MainNavbar/About/Chairman'sMessage";
import Secretary from "./Components/MainNavbar/About/Secretary'sMessage";
import President from "./Components/MainNavbar/About/President'sMessage";
import Principal from "./Components/MainNavbar/About/Principal'sMessage";
import Science from "./Components/MainNavbar/Academics/Course/Science";
import ANM from "./Components/MainNavbar/Academics/Course/ANM";
import DMLT from "./Components/MainNavbar/Academics/Course/DMLT";
import Dpharma from "./Components/MainNavbar/Academics/Course/Dpharma";
import GNM from "./Components/MainNavbar/Academics/Course/GNM";
import HMT from "./Components/MainNavbar/Academics/Course/HMT";
import Nursing from "./Components/MainNavbar/Academics/Course/Nursing";
import Chse from "./Components/MainNavbar/Academics/ChsePattern";
import Method from "./Components/MainNavbar/Academics/Method";
import Strategy from "./Components/MainNavbar/Academics/Strategy";
import Aprocedure from "./Components/MainNavbar/Admission/Procedure";
import FeeStructure from "./Components/MainNavbar/Admission/FeeStructure";
import Facilities from "./Components/MainNavbar/Admission/Facilities";
import Loan from "./Components/MainNavbar/Admission/Loan";
import Scholarship from "./Components/MainNavbar/Admission/Scholarship";
import Brochure from "./Components/MainNavbar/Admission/Brochure";
import Examination from "./Components/MainNavbar/Examination/Examinations";
import Result from "./Components/MainNavbar/Examination/Result";
import TPD from "./Components/MainNavbar/T&P/TPD";
import Procedure from "./Components/MainNavbar/T&P/Procedure";
import Tprogram from "./Components/MainNavbar/T&P/Tprogram";
import Preport from "./Components/MainNavbar/T&P/Preport";
import Recruiter from "./Components/MainNavbar/T&P/Recruiter";
import Alumni from "./Components/MainNavbar/Alumini";
import Gallery from "./Components/MainNavbar/Gallery";
import Contact from "./Components/MainNavbar/Contact";
import Footer from "./Components/Footer";
import fire from "./firebase";
import "firebase/analytics";

import { Switch, Route } from "react-router-dom";

function App() {
  fire.analytics();
  return (
    <div className="App">
        <Navs />
        <Switch>
          <Route path="/" exact><Home /></Route>
          <Route path="/Admission-Enquiry" exact><Enquiry /></Route>
          <Route path="/Placement" exact><Placement /></Route>
          <Route path="/Career" exact><Career /></Route>
          <Route path="/Admin" exact><Admin /></Route>
          <Route path="/The-Institute" exact><Institute /></Route>
          <Route path="/Chairman's-Message" exact><Chairman /></Route>
          <Route path="/Secretary's-Message" exact><Secretary /></Route>
          <Route path="/President's-Message" exact><President /></Route>
          <Route path="/Principal's-Message" exact><Principal /></Route>
          <Route path="/+2-Science" exact><Science /></Route>
          <Route path="/DMLT" exact><DMLT /></Route>
          <Route path="/D.Pharma" exact><Dpharma /></Route>
          <Route path="/ANM" exact><ANM /></Route>
          <Route path="/GNM" exact><GNM /></Route>
          <Route path="/B.Sc-Nursing" exact><Nursing /></Route>
          <Route path="/Hotel-Management&Tourism" exact><HMT /></Route>
          <Route path="/CHSE-Pattern" exact><Chse /></Route>
          <Route path="/Teaching-Methodology" exact><Method /></Route>
          <Route path="/Our-Boosting-Strategy" exact><Strategy /></Route>
          <Route path="/Admission-Procedure" exact><Aprocedure /></Route>
          <Route path="/Fee-Structure" exact><FeeStructure /></Route>
          <Route path="/Facilities" exact><Facilities /></Route>
          <Route path="/Education-Loan" exact><Loan /></Route>
          <Route path="/Student-Scholarship" exact><Scholarship /></Route>
          <Route path="/e-Brochure" exact><Brochure /></Route>
          <Route path="/Examination" exact><Examination /></Route>
          <Route path="/Result" exact><Result /></Route>
          <Route path="/Training&Placement-Department" exact><TPD /></Route>
          <Route path="/Procedure" exact><Procedure /></Route>
          <Route path="/Training-Program" exact><Tprogram /></Route>
          <Route path="/Placement-Report" exact><Preport /></Route>
          <Route path="/Our-Recruiters" exact><Recruiter /></Route>
          <Route path="/Alumni" exact><Alumni /></Route>
          <Route path="/Gallery" exact><Gallery /></Route>
          <Route path="/Contact-Us" exact><Contact /></Route>
        </Switch>

        <Footer />
    </div>
  );
}

export default App;
