import { Container } from "react-bootstrap";
import "../../Component.css";
import fire from "../../../firebase";
import "firebase/firestore";
import { useEffect, useState } from "react";

export default function Result() {
    const db = fire.firestore();
    const [result,setResult] = useState([]);
    useEffect( () => {
        db.collection('Result').orderBy('time', 'desc').onSnapshot(snap => {
            setResult(snap.docs.map(doc => ({
                id : doc.id,
                examresult : doc.data(),
            })))
        })
    },[db]);

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-3">Results</h1>
            {result.map( i => (
                <div key={i.id} className="mt-2 bg-primary" style={{padding: "10px 20px 7px 40px", borderRadius: "10px"}}>
                    <a href={i.examresult.url} style={{textDecoration: "none", color: "white"}} target="_black"><h4 style={{fontWeight: "400"}}>{i.examresult.title}</h4></a>
                </div>
            ))}
            
        </Container>
    )
}