import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Facilities() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2>FACILITIES</h2>
            <div className="text-center mb-5 mt-4">
                <h5>A.C. Class Rooms</h5>
                <h5>Well Equipped Hostel</h5>
                <h5>Laboratory</h5>
                <h5>Library</h5>
                <h5>Integrated Coaching</h5>
                <h5>24X7 CCTV Surveillance</h5>
            </div>
            <p>Learning beyond the class-room is most important factor for the academic excellence of the students. That is why Aryabhatt College is equipped with separate in-campus hostels for boys and girls with 24 x 7 monitoring system. The students enjoy a homely atmosphere in the hostel for their productive study process. All the hostels are endowed with all modern amenities, spacious rooms for comfortable study, room wise separate toilets, cozy bed with luxurious mattress, pillow, study table and individual cupboard etc.</p>
            <div className="text-center mt-5">
                <h6>24x7 Monitoring System</h6>
                <h6>24x7 Tight Security</h6>
                <h6>Guided Study Hour</h6>
                <h6>Nutritious and Hygienic Food</h6>
                <h6>Provision of Four Times Food a Day</h6>
                <h6>Separate Play-ground for Boys & Girls</h6>
                <h6>Modern Infrastructure</h6>
                <h6>Neat and Clean Surrounding</h6>
            </div>
        </Container>
    )
}