import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Strategy() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2>OUR BOOSTING STRATEGY</h2>
            <p className="mt-5">The Aryabhatt College, Jharsuguda creates a conducive academic and competitive environment to make the students brain produce chemicals that can inculcate positive feelings and creativity among the students.</p>

            <h6 className="mt-4">Comparison of weekly assessment:</h6>
            <p>Students appear weekly tests. The results are microscopically assessed. Students are given scope to compare their results with their nearest competitors. Certain mercurial steps are taken to make the students highly competitive and they automatically produce the best result.</p>

            <h6 className="mt-4">Running Cup:</h6>
            <p>Based on the Monthly results, a Running Cup is given to the topper who retains the cup for one month. It makes the students to have a separate identity and he/she automatically becomes more studious.</p>
        </Container>
    )
}