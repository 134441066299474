import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Chse() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2>CHSE PATTERN</h2>
            <p>At Aryabhatt Higher Secondary School, the teachers and students are trained by the system of unique teaching methodology productive learning process correspondingly. The entire course is scheduled to be completed in two academic sessions. In each session there will be fixed component of academic teaching (CHSE pattern) and competitive teaching as well.</p>
            <p>The classes held six days a week and on every Monday weekly test is conducted. The classes are held from 9 am to 4 pm. The classes are conducted in such a manner that the fundamentals in all subjects are cleared at the grass-root level. The lecturers provide running notes in the class-rooms, based on CHSE pattern. During self – study, if a student faces any doubt in any subjects, he/ she may meet the concerned lecturer for that, during the doubt clearing session (5 pm to 6 pm). Also the students are exposed to regular classroom tests on subjective and objective pattern in order to prepare them for their final examination. The Principal works as a guardian of every student to monitor all sorts of progress in Aryabhatt.</p>
        </Container>
    )
}