import { Container, Row, Col } from "react-bootstrap";
import "../Component.css";
import fire from "../../firebase";
import "firebase/firestore";
import {useEffect, useState} from "react";

export default function Gallery() {

    const db = fire.firestore();
    const [galleryImg, setGalleryImg] = useState([])


    useEffect( () => {
        db.collection('Gallery').orderBy('time', 'desc').onSnapshot(snap => {
            setGalleryImg(snap.docs.map(doc => ({
                id : doc.id,
                galleryimage : doc.data().url,
            })))
        })
    },[db]);

    return (
        <Container className="mt-5">
            <h1 className="text-center">Gallery</h1>
            <Row className="mt-5">
                {galleryImg.map(i => (
                    <Col md={4} key={i.id}><a href={i.galleryimage} target="_blank" rel="noreferrer"><img src={i.galleryimage} width="100%" alt='College-Gallery' style={{borderRadius: "10px"}} /></a></Col>
                ))}
            </Row>
        </Container>
    )
}