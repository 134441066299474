import { Container } from "react-bootstrap";
import "../../../Component.css";

export default function GNM() {
    return (
        <Container className="mt-3">
            <h1>GNM Admissions</h1>
            <table>
            <tr>
                <th>Category</th>
                <th>Highlights</th>
            </tr>
            <tr>
                <td>Course Name</td>
                <td>General Nursing and Midwifery(GNM)</td>
            </tr>
            <tr>
                <td>Duration</td>
                <td>3 Years</td>
            </tr>
            <tr>
                <td>Minimum Qualification</td>
                <td>10+2</td>
            </tr>
            <tr>
                <td>Admission process</td>
                <td>Merit/ Entrance Base</td>
            </tr>
            <tr>
                <td>Fee</td>
                <td>
                Hosteller - Rs 1,20,000/- (Annually)<br />
                Day Scholar - Rs 90,000/- (Annually)
                </td>
            </tr>
            </table>

            <h1>GNM Eligibility Criteria</h1>
            <table>
            <tr>
                <th>Course Name</th>
                <th>Eligibility Criteria</th>
            </tr>
            <tr>
                <td>GNM</td>
                <td>
                <ul>
                    <li>
                    Candidate must have at least 40-50% marks in Class 12 with
                    subjects as Physics, Chemistry and Biology.
                    </li>
                    <li>The minimum age requirement is 17 years.</li>
                    <li>Student's age should not exceed more than 35 years.</li>
                    <li>
                    Candidates awaiting their Higher Secondary Exam results can also
                    apply for the GNM course.
                    </li>
                </ul>
                </td>
            </tr>
            </table>
            <h2>List of Documents Required for GNM</h2>
            <ul>
            <li>Copy of the submitted application form signed by the candidate.</li>
            <li>Original 10th pass certificate and mark sheet</li>
            <li>Original 12th pass certificate and mark sheet.</li>
            <li>
                Physically Handicapped certificate issued by the competent authority [in
                case of physically handicapped candidates].
            </li>
            <li>Original Transfer certificate</li>
            <li>
                Original caste certificate in case of SC/ST candidates issued by the
                Competent Authority.
            </li>
            <li>Authentic proof of passing Odia as a subject at M.E.</li>
            <li>
                Domicile/ Residential/ Nativity certificate from competent authority.
            </li>
            <li>
                Original medical certificate issued by the Govt. Allopathic medical
                officer.
            </li>
            <li>
                Original e-Receipt or Bank Acknowledgement copy of the challan deposit
                slip only towards application fees.
            </li>
            <li>
                Original Character certificate issued by the Institution last attended.
            </li>
            <li>Two self-attested recent colored passport sized photographs.</li>
            </ul>
        </Container>
    )
}