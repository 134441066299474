import { Carousel } from "react-bootstrap";

export default function Slider() {
    return (
        <Carousel>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Carousel%20Images%2FIMG-20210602-WA0108.jpg?alt=media&token=d8c87237-9354-41b7-98a1-b2ea145108ec"
                alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Carousel%20Images%2FAIMS%20Building.jpg?alt=media&token=f517e7da-a18a-4cdc-9c00-8e65176a1cf7"
                alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Carousel%20Images%2FIMG-20210603-WA0072.jpg?alt=media&token=03b96705-fc97-4cbf-a7c6-de97a95b7623"
                alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Carousel%20Images%2FIMG-20210603-WA0082.jpg?alt=media&token=1b7d89ad-a5e9-4054-8d3a-0a06678f7060"
                alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Carousel%20Images%2FIMG-20210603-WA0073.jpg?alt=media&token=b5b96e8b-275e-4ccd-9536-f5237969cb30"
                alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
    )
}