import firebase from "firebase/app";

const fire = firebase.initializeApp({
    apiKey: "AIzaSyBMvGKqyb77B6YlN0C2Xwmt0J_gffj_Kn0",
    authDomain: "aryabhatt-college.firebaseapp.com",
    projectId: "aryabhatt-college",
    storageBucket: "aryabhatt-college.appspot.com",
    messagingSenderId: "197183561773",
    appId: "1:197183561773:web:2936020f7bd2b9bd10d8a4",
    measurementId: "G-RHYJ0K71EB"
  });

export default fire;