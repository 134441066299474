import "../Component.css";
import { Form, Button, Container, Breadcrumb } from "react-bootstrap";
import fire from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useState } from "react";

export default function Admin() {
    const auth = fire.auth();
    const db = fire.firestore();

    const [email,setEmail] = useState('');
    const [pass,setPass] = useState('');
    const [userState,setUserState] = useState();

    const [date,setDate] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [contentURL, setContentURL] = useState('');

    const [imageURL, setImageURL] = useState('');
    const [pdfTitle, setPDFTitle] = useState('');
    const [pdfURL, setPDFURL] = useState('');
    const [annContent, setAnnContent] = useState('');

    const [aenq, setAenq] = useState([]);
    const [career, setCareer] = useState([]);

    const announce = (e) => {
        e.preventDefault();
        db.collection('Announcement').doc('ann').update({
            content : annContent,
        })
        setAnnContent('');
        window.alert('Announcement Updated')
    }

    const addNews = (e) => {
        e.preventDefault();
        db.collection("News").add({
            date : date,
            title : title,
            content : content,
            contentURL : contentURL,
            time : firebase.firestore.FieldValue.serverTimestamp(),
        })
        setDate('');
        setTitle('');
        setContent('');
        setContentURL('');
        window.alert('News Added :)')
    }

    const addImage = (e) => {
        e.preventDefault();
        db.collection('Gallery').add({
            url : imageURL,
            time : firebase.firestore.FieldValue.serverTimestamp(),
        })
        setImageURL('');
        window.alert('Added To Gallery :)')
    }
    const addResult = (e) => {
        e.preventDefault();
        db.collection('Result').add({
            url : pdfURL,
            title : pdfTitle,
            time : firebase.firestore.FieldValue.serverTimestamp(),
        })
        setPDFURL('');
        setPDFTitle('')
        window.alert('Result File Added :)')
    }

    const signout = () => {
        auth.signOut();
        setUserState()
    }

    const loginHandler = (e) => {
        e.preventDefault();
        auth.signInWithEmailAndPassword(email,pass)
        .catch((err) => {
            const ec = err.code;
            window.alert(ec);
        })
    }
    auth.onAuthStateChanged((user) => {
        if (user) {
        setUserState(user);
        } else {
        auth.signOut();
        setUserState(false)
        }
    })

    useEffect( () => {
        db.collection('Admission_Enquiry').orderBy('time', 'desc').onSnapshot(snap => {
            setAenq(snap.docs.map(doc => ({
                id : doc.id,
                details : doc.data()
            })))
            })
    },[db]);

    useEffect( () => {
        db.collection('Career').orderBy('time', 'desc').onSnapshot(snap => {
            setCareer(snap.docs.map(doc => ({
                id : doc.id,
                detail : doc.data(),
            })))
        })
    })
    return (
        <Container className="mt-3">
            
                {(userState)
                    ?  <div>
                            <Button variant="danger" type="submit" onClick={signout}>LOG OUT</Button>
                            <Form.Group controlId="exampleForm.ControlInput1" className="mt-3"> 
                                <h2>Announcement</h2>
                                <Form.Control type="text" placeholder="your announcement" value={annContent} onChange={(e)=>setAnnContent(e.target.value)} />
                                <Button className="mt-2 bg-warning" style={{border: "none",fontWeight: "600"}} onClick={announce} >POST</Button>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput1" className="mt-3">
                                <h2>Info / News & Events / Placement News</h2>
                                <Form.Control type="text" className="mt-2" placeholder="News Date:" value={date} onChange={(e)=>setDate(e.target.value)} />
                                <Form.Control type="text" className="mt-2" placeholder="News Title:" value={title} onChange={(e)=>setTitle(e.target.value)} />
                                <Form.Control type="text" className="mt-2" placeholder="News Content:" value={content} onChange={(e)=>setContent(e.target.value)} />
                                <Form.Control type="text" className="mt-2" placeholder="News PDF URL:" value={contentURL} onChange={(e)=>setContentURL(e.target.value)} />
                                <Button className="mt-2 bg-success" style={{border: "none",fontWeight: "600"}} onClick={addNews}>POST</Button>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput1" className="mt-3"> 
                                <h2>Upload Image To Gallery</h2>
                                <Form.Control type="text" placeholder="Image URL" value={imageURL} onChange={(e)=>setImageURL(e.target.value)} />
                                <Button className="mt-2 bg-primary" style={{border: "none",fontWeight: "600"}} onClick={addImage}>UPLOAD</Button>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput1" className="mt-3"> 
                                <h2>Upload Result PDF file</h2>
                                <Form.Control type="text" className="mt-2" placeholder="RESULT FILE TITLE" value={pdfTitle} onChange={(e)=>setPDFTitle(e.target.value)} />
                                <Form.Control type="text" className="mt-2" placeholder="RESULT FILE URL" value={pdfURL} onChange={(e)=>setPDFURL(e.target.value)} />
                                <Button className="mt-2 bg-primary" style={{border: "none",fontWeight: "600"}} onClick={addResult}>UPLOAD</Button>
                            </Form.Group>

                            <div className="mt-4">
                                <h2 className="mb-3">Admission Enquiries:</h2>
                                {aenq.map(i => (
                                    <Breadcrumb key={i.id}>
                                        <Breadcrumb.Item active>Name:- {i.details.Name}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Email:- {i.details.Email}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Phone:- {i.details.Phone}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>State:- {i.details.State}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>City:- {i.details.City}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Qualification:- {i.details.Qualification}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Course:- {i.details.Course}</Breadcrumb.Item>
                                    </Breadcrumb>
                                ))}
                                
                            </div>
                            
                            <div className="mt-4">
                                <h2 className="mb-3">Join Requests:</h2>
                                {career.map(i => (
                                    <Breadcrumb key={i.id}>
                                        <Breadcrumb.Item active>Name:- {i.detail.name}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Email:- {i.detail.email}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Phone:- {i.detail.phone}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Category:- {i.detail.Category}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Post:- {i.detail.post}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Message:- {i.detail.Message}</Breadcrumb.Item>
                                    </Breadcrumb>
                                ))}
                                
                            </div>
                        </div>
                    :  <Form className="login-form">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>User ID:</Form.Label>
                                <Form.Control type="email" placeholder="UserName" vlaue={email} onChange={(e)=>setEmail(e.target.value)} />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" vlaue={pass} onChange={(e)=>setPass(e.target.value)} />
                            </Form.Group>
                            <Button variant="primary" type="submit" onClick={loginHandler}>LOGIN</Button>
                        </Form>
                }

            
        </Container>
    )
}