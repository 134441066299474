import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Practice() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2 className="mt-5 mb-5">EXAMINATIONS</h2>

            <h4>WEEKLY TEST SERIES (CHSE)</h4>
            <p>In every weekend a Test based on CHSE pattern are conducted by the college starting from the beginning of the month of July and will continue up to the end of September every year.</p>

            <h4 className="mt-4">REVISION TEST SERIES (RTS)</h4>
            <p>This is a unique test conducted every week along with the revision classes during the month of September/October every year.</p>

            <h4 className="mt-4">PRACTICE TEST SERIES (PTS)</h4>
            <p>This is conducted during the month of November/December every year which helps the 2nd year students for practicing their skills and to enhance their confidence.</p>

            <h4 className="mt-4">PRE-BOARD (CHSE)</h4>
            <p>This is a test on full syllabus of the CHSE pattern conducted in the month of November every year.</p>

            <h4 className="mt-4">VERY SIMILAR TEST(VST)</h4>
            <p>This is the last test for 2nd year students conducted on the month of January covering the full syllabus in the very much identical pattern of ANNUAL CHSE EXAM.</p>
        </Container>
    )
}