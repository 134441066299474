import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Principal() {
    return (
        <Container className="pt-5 allign">
            <h2 className='mb-3'>PRINCIPAL’S MESSAGE</h2>
            <p>“Education is not solely about earning a great living. It means living a great life.”<br/>
                - Brad Henry
            </p>
            <p>Education is the manifestation of love and my most cherished possession. Education drives away ignorance. Through illumination, it emboldens man to a righteous thought and action and empowers woman and enlarges the horizon of her mind. It energizes a society and enables a man to earn his living with respect and praise.</p>
            <p>The College is situated at Mauli Maa Nagar, BTM Chowk with advantage of connectivity through air, road and trains. Its contiguity to the town is a distinct advantage cynosure of all eyes. The infrastructure that the college has is second to none. We have dedicated teachers who open the door for the students but at the same time expect them to enter it by themselves. We mould our students to be self confident and pragmatic so that when they enter the world after their studies, they face various challenges with courage, conviction and success.</p>
            <p>We, in order to accomplish our vision and mission are prepared to take as much effort as possible for the betterment of academic scenario in India. We believe that education is an effective medium of social transformation. We are excited looking at the bright and successful careers of our thousands of students, which subsequently benefit the society. We feel proud that we are part of such an excellent institute, which is shaping modern India.</p>
            <p>Aryabhatt College is endowed with progressive and futuristic outlook, aims at continuous growth in the quality of all academic activities with a sense of commitment to fully meet the expectations of all the stake holders at large.</p>
            <p>Our college cares for the individual development of each and every student. We accord prime importance to the behavioral discipline, moral integrity and cognitive development of our students. We offer integrated services for the multi–facetted development of our students. We strive to teach not only academic programmes but also life skills needed for students’ self development with highly resourceful faculty. </p>
            <p>India is known for its rich values, culture and heritage. I impress upon all our young learning community to emerge as her proud and worthy citizens. I wish the best of fortune, peace and prosperity to all those who contribute to the noble task of spreading education and its manifest qualities, aims and objectives.</p>
        </Container>
    )
}