import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Procedure() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2>ADMISSION PROCEDURE</h2>
            <h5 className="mt-4">Compulsory Subject</h5>
            <p>English, M.I.L.(Oriya/Alt. English/Hindi - any one) , Elective subjects are 1st - Physics, 2nd - Chemistry, 3rd - Mathematics (All the subjects are compulsory) 4th elective subject - Biology (B), Information Technology (IT), (The student is to choose any one of the 4th elective subjects). Subject combinations: PCM-B, PCM-IT.</p>

            <h5>Eligibility for Admission</h5>
            <p>Students who have passed 10th Board Examination conducted by BSE, Odisha / CBSE / ICSE are eligible for admission. Admission will be strictly on merit basis under Student Academic Management System (SAMS) as per Resolution No.: 1-A-HE-4A-2010-3702/HE dated 20.02.2010 of Government of Odisha, Higher Education Department. Candidates selected under e-admission procedure (SAMS) for admission are intimated individually by post/e-mail in prescribed intimation letter formulated by Govt. of Odisha regarding the date of admission and fees to be paid. The names of the selected candidates are also displayed in the notice board. In case of any complain the decision of the Principal of Destination Higher Secondary School is final and binding.</p>
            
            <h5>How to Apply</h5>
            <p>	The admission into Higher Secondary School shall be done through ON-LINE e-admission procedure under SAMS for the academic session 2021-22. Students shall download the Common Application Form from Govt. of Odisha website <a target="_blank" rel="noreferrer" href="https://dhe.odisha.gov.in">(www.dheodisha.gov.in)</a> only after result publication of Board of Secondary Education, Odisha.</p>

            <h5>Submission of Common Application Form (CAF)</h5>
            <p>	After the 'Application Forms' is collected from Govt. website for admission into Higher Secondary streams, the student must read the CAF carefully and fill it in CAPITAL LETTERS and submit the form online only. Printed copy of the form and certificates will be submitted in Destination Higher Secondary School / Resource Centre / Nodal Centre within prescribed period by Govt. of Odisha.</p>

            <h5>Summer Course</h5>
            <p>An application Form along with prospectus is available at Aryabhatt Higher Secondary School, Jharsuguda, Odisha and also you may visit the official website of our college that is <a target="_blank" rel="noreferrer" href="https://aryabhattcollege.org.in">https://aryabhattcollege.org.in/</a> to get enrolled into the Summer Course.</p>

            <h5>Our Mission for Summer Course</h5>
            <p>Our mission is to enable, encourage and enrich each and every student to realize his/her dreams to be a best academician, Doctor or an Engineer. Effective Education trains our students during these two months to excel in CHSE as well as competitive examinations, by clearing their fundamentals so that a student can start a colorful beginning to higher secondary syllabus.</p>

            <h5>Documents to be submitted at the time of Admission</h5>
            <ol>
                <li>SLC in Original.</li>
                <li>Attested photocopy of Mark-sheet of 10th Board Exam.</li>
                <li>Original conduct certificate.</li>
                <li>Four recent passport size colour photographs.</li>
                <li>Attested photocopy of caste certificate (for reserve categories only).</li>
                <li>Migration certificate in original if coming from other Board.</li>
            </ol>
        </Container>
    )
}