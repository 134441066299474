import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Method() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2>TEACHING METHODOLOGY</h2>
            <p className="mt-4">We have adopted a distinctive teaching methodology which befits our ever-guided approach 'Convergence of academics and competitions: The mode of teaching aims at the fundamental teaching. We have prepared our own course progress syllabus where the chapters of all subjects are taught harmoniously in close proximity with advanced teaching. A galaxy of dedicated and experienced teachers makes their full effort to impart innovative teaching to the utmost satisfaction of the students.</p>
            <p>The entire course is to be completed in two academic phases. In each phase there will be a fixed component of academic teaching and competitive training in Aryabhatt. It is our experience that those students who aspire for a career in Medical/Engineering should start preparation for the same from class Xl.</p>
            <div className='text-center mt-5'>
                <h4 className="mt-4">A Unique Academic Schedule</h4>
                <h4 className="mt-4">Provision of Systematic notes</h4>
                <h4 className="mt-4">Rigorous Practice & Test on Exam Pattern</h4>
                <h4 className="mt-4">Doubt Clearing Classes for academic & Competition</h4>
                <h4 className="mt-4">5 days in a week competitive Classes</h4>
                <h4 className="mt-4">Weekly, Monthly, Revision, Practice & Very Similar Test</h4>
                <h4 className="mt-4" style={{display: "flex", flexDirection: "column"}}>
                    <span>ARYABHATT COLLEGE</span>
                    <span style={{fontWeight: '500'}}>(Convergence of Academics & Competition)</span>
                </h4>
            </div>
        </Container>
    )
}