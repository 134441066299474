import { Container, Form, Button } from "react-bootstrap";
import fire from "../../firebase";
import "firebase/firestore";
import "../Component.css";
import firebase from "firebase/app";
import { useState } from "react";

export default function Career() {
    const db = fire.firestore();

    const [cnm, setCnm] = useState('');
    const [cem, setCem] = useState('');
    const [cph, setCph] = useState('');
    const [cca, setCca] = useState('');
    const [cpo, setCpo] = useState('');
    const [cmsg, setCmsg] = useState('');

    const apply = () => {
        db.collection("Career").add({
            name : cnm,
            email : cem,
            phone : cph,
            Category : cca,
            post : cpo,
            Message : cmsg,
            time : firebase.firestore.FieldValue.serverTimestamp()
        })
        setCnm('');
        setCem('');
        setCph('');
        setCca('');
        setCpo('');
        setCmsg('');

        window.alert('Form Submitted');
  }
    return (
        <Container className="mt-3">
            <h2 className="mb-3">Join Us</h2>
            <form>
            <Form.Group controlId="exampleForm.ControlInput1" className="mt-3">
                <Form.Control type="text" className="mt-2" placeholder="Enter Name" value={cnm} onChange={(e)=>setCnm(e.target.value)} required />
                <Form.Control type="email" className="mt-2" placeholder="Enter Email Address" value={cem} onChange={(e)=>setCem(e.target.value)} required />
                <Form.Control type="text" className="mt-2" placeholder="Enter Phone Number" value={cph} onChange={(e)=>setCph(e.target.value)} required />
                <Form.Control as="select" className="mt-2" value={cca} onChange={(e)=>setCca(e.target.value)} required>
                    <option>Select Category</option>
                    <option value="Teaching">Teaching</option>
                    <option value="Non-Teaching">Non-Teaching</option>
                </Form.Control>
                <Form.Control as="select" className="mt-2" value={cpo} onChange={(e)=>setCpo(e.target.value)} required>
                    <option>Select Post</option>
                    <option value="Assistant Professor">Assistant Professor</option>
                    <option value="Associate Professor">Associate Professor</option>
                    <option value="Chemistry">Chemistry</option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                    <option value="IT">IT</option>
                    <option value="Mathematics">Mathematics</option>
                    <option value="Odia">Odia</option>
                    <option value="Physics">Physics</option>
                    <option value="Principal">Principal</option>
                    <option value="Tutor">Tutor</option>

                    <option value="Accountant">Accountant</option>
                    <option value="Admission Officer">Admission Officer</option>
                    <option value="Cook">Cook</option>
                    <option value="Hostel Warden">Hostel Warden</option>
                    <option value="Kitchen Staff">Kitchen Staff</option>
                    <option value="Peon">Peon</option>
                    <option value="Sweeper">Sweeper</option>
                    <option value="Telecaller">Telecaller</option>
                </Form.Control>
                
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label className="mt-2">Message / Feedback</Form.Label>
                    <Form.Control as="textarea" rows={3}  value={cmsg} onChange={(e)=>setCmsg(e.target.value)} required />
                </Form.Group>
                <div className="">Recruits are advised to mail their CV (Curriculum Vitae) to <a href="mailto: recruitment@aryabhattcollege.org.in" target="_blank" rel="noreferrer">recruitment@aryabhattcollege.org.in</a>.</div>            
                <Button className="mt-2 bg-primary" style={{border: "none",fontWeight: "600"}} onClick={apply} disabled={!cnm || !cph || !cpo}>SUBMIT</Button>
            </Form.Group>
            </form>
        </Container>
    )
}