import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Loan() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-3 allign">
            <h2>Education Loan</h2>
            <p>Education loan or student loan, are advances made by banks or financial organizations for the purpose of meeting higher education expenses of the students. Under this special loan scheme, the meritorious students having excellent educational qualification are offered financial help to study in Aryabhatt College. The Company Financepeer offers loan at 0% interest and our partner banks HDFC, IDFC, Union Bank offer such loans to the deserving candidates at a low interest rate with flexible terms and conditions. Moreover, the documentation process is kept minimal to enable the students get the loan amount within a quick span without much complexity.</p>
            <h2>Education Loan Eligibility</h2>
            <p>Since these loans are offered to brilliant students who are unable to meet the expenses of their higher education, the basic education loan eligibility is the academic excellence and achievements of the students. In other words, the eligibility of the applicants is assessed on the basis of their academic performance as cited in the mark sheets of previous exams.</p>
            <p>The vital education loan eligibility criteria that the candidates need to fulfill to get approval for the loan are listed out here:</p>
            <ul>
                <li>The candidate applying for the loan must be a resident of India.</li>
                <li>He/she must have confirmed admission in Aryabhatt College.</li>
                <li>The age of the candidate must fall within the bracket of 18 to 35 years during loan application.</li>
                <li>He/she must be undergoing a graduate/postgraduate degree or a PG diploma.</li>
                <li>Students pursuing full-time courses need to have a co-applicant who can be either parent/guardian or spouse/parent-in-law (in case of married candidates).</li>
            </ul>
        </Container>
    )
}