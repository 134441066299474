import { Container } from "react-bootstrap";
import "../../../Component.css";

export default function Dpharma() {
    return (
        <Container className="mt-3">
            <h1>D.Pharm Admission</h1>
            <p>
            Some of the major highlights of D.Pharm admission in India is as follows:
            </p>
            <table>
            <tr>
                <th>Category</th>
                <th>Highlights</th>
            </tr>
            <tr>
                <td>Level</td>
                <td>Diploma</td>
            </tr>
            <tr>
                <td>Course Name</td>
                <td>Diploma in Pharmacy</td>
            </tr>
            <tr>
                <td>Duration</td>
                <td>2 Years</td>
            </tr>
            <tr>
                <td>Type</td>
                <td>Semester Wise</td>
            </tr>
            <tr>
                <td>Minimum Qualification</td>
                <td>10+2 or equivalent</td>
            </tr>
            <tr>
                <td>Admission process</td>
                <td>Merit/ Entrance Base</td>
            </tr>
            <tr>
                <td>Fee</td>
                <td>
                Hosteller - Rs 1,20,000/- (Annually)<br />
                Day Scholar - Rs 90,000/- (Annually)
                </td>
            </tr>
            </table>

            <h1>D.Pharm Eligibility Criteria</h1>
            <table>
            <tr>
                <th>Course Name</th>
                <th>Eligibility Criteria</th>
            </tr>
            <tr>
                <td>D.Pharm</td>
                <td>
                <ul>
                    <li>
                    In order to take admission, candidate must have passed the 10+2
                    examination or equivalent with minimum 50% marks from any
                    recognized board of education.
                    </li>
                    <li>
                    Must have studied Physics,Chemistry and Biology as their main
                    subjects.
                    </li>

                    <li>
                    At the time of admission, candidates should be a minimum of 17
                    years of age.
                    </li>
                </ul>
                </td>
            </tr>
            </table>

            <h2>Documents Required for D.Pharm Admissions</h2>
            <p>
            The list of documents required along with the D.Pharm application form are
            as follows:
            </p>
            <table>
            <tr>
                <td>Class 10th Certificate and its mark sheet</td>
                <td>Payment Receipt of Admission Fee</td>
            </tr>
            <tr>
                <td>Class 12th Certificate and its mark sheet</td>
                <td>Character certificate</td>
            </tr>
            <tr>
                <td>Diploma Certificate(If Applicable)</td>
                <td>Caste Certificate(If Applicable)</td>
            </tr>
            <tr>
                <td>Migration Certificate</td>
                <td>Transfer Certificate</td>
            </tr>
            </table>
        </Container>
    )
}