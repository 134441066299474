import "../Component.css";
import { Container } from "react-bootstrap";

export default function Procedure() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2>PROCEDURE:</h2>
            <p>Campus recruitment for final year students starts from June onwards every year. Recruitment after passing out of the campus will also be done depending on the availability of non-placed students. Interested recruits are requested to mail the following details to <a href="mailto: recruitment@aryabhattcollege.org.in" target="_blank" rel="noreferrer">recruitment@aryabhattcollege.org.in</a> </p>

            <div style={{border: "1px solid black", width: "fit-content"}}>
                <ol>
                    <li>Job Profile</li>
                    <li>Job Location</li>
                    <li>Training Period</li>
                    <li>CTC during & after Training</li>
                    <li>Designation before & after Training</li>
                    <li>Service agreement if any - No. of years & bond amount if any</li>
                    <li>Degrees & Branches required</li>
                    <li>Eligibility Criteria - Marks (X / XII/dip / Current degree/UG for PG) and Arrear Status.</li>
                    <li>Selection Process- Test (Online / Not)/GD/Interview</li>
                    <li>Facilities required for campus recruitment</li>
                    <li>Preferred dates to visit</li>
                </ol>
            </div>
            <h6 className="mt-2" style={{border: "1px solid black", width: "fit-content"}}>NB: Placement with Paid Training by students and consulting fee attached placements are not allowed.</h6>

            <p>Based on the above data, students will register at Placement Centre. No. of interested students will be informed to the recruiting company.</p>
            <p>Based on the no. of interested students, company can fix the venue either at college or at their office. Resumes of the interested students can also be sent to the company for shortlisting at their end. If the policy of the company is to conduct a pooled campus drive for colleges, we are ready to conduct here in our campus.</p>
            <p>List of selected candidates and offer letters shall be given to the Placement Officer at the end of the process. If there is any delay in announcement of results, students will be permitted to attend the next company. If the students get placement in the next company, they will not be permitted to get the offer from the previous company if get selected.</p>
            <p>The recruiters are requested to give the feedback of the quality of the students at the end of the selection process and also after the training period. This will help us to improve continuously and offer better numbers than the previous year. Dream offer will be given to students.</p>
            <p>To the companies that offer a CTC of more than Rs.1.8 LPA.</p>
            <p>To Pharmacy Companies so that the companies don't fall in short of quality students and also students are given the option to choose their career.</p>
            <p>If the student gets placed in the Dream Offer, his previous offer will be cancelled by informing the previous recruiter. This is done to make sure that the student join in only one company. Students placed in the dream offer will not be permitted to attend subsequent Dream offer recruitments.</p>
        </Container>
    )
}