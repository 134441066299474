import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Program() {
    window.scrollTo(0,0);

    return (
        <Container className="mt-5 allign">
            <h2>Training Program</h2>
            <p>Placement Cell of Aryabhatt College identifies the industry needs and demands from time to time and prepares its graduating engineering students who are on the threshold of an exciting career in the field of technology. The placement cell is committed to continuous learning, and creating positive ideas, through rigorous training to prepare the young techies. We are dedicated to help our students achieve worthwhile positions in different fields in the corporate sectors. Students' communication skills are honed through communication and organizational behavior courses. For overall personality development regular counselling sessions are organized on various prospects of personality development like positive thinking, body language, time management, stress management and communicative skills. We also organize off-campus recruitment programme, to facilitate the students of other technical Institute of the State to learn and improvise them. Seminar and talks are delivered by distinguished people from the industry and institutions.</p>

            <ol className="mt-4">
                <li>Group Discussions</li>
                <li>Industry Readiness Tests</li>
                <li>Dummy seminars and talk</li>
                <li>Corporate Etiquette & other soft skills</li>
                <li>Personal interviews</li>
                <li>Psychometric Tests</li>
                <li>Extempore speech</li>
                <li>Mock Interviews</li>
            </ol>

            <p>The Training & placement Cell is committed to provide all possible assistance to its graduates in their efforts to find employment. This commitment is demonstrated by the existence of a full-time professor in charge. The Training & Placement service operates year-round to facilitate contacts between companies and graduates. Staffs are available to respond to student’s question and concern of all kinds. This may include advice on placement procedures help with preparation of applications and resumes and practice for interviews. The aim is to ensure that students have the information and skills necessary for an effective job search. Those considering careers in education or in related fields are offered detailed career guidance at the Training &Placement Cell.</p>
        </Container>
    )
}