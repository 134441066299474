import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Institute() {
    return (
        <Container className="pt-5 allign">
            <h2 className='mb-3'>THE INSTITUTE</h2>
            <p>
                Aryabhatt is the fully residential higher secondary school in Odisha operating in its own premises, with a commitment to provide quality education, both in academic and competitions. It is also equipped with separate boys and girls Hostels which caters to the every needs that fulfils the dreams and aspirations of students. Again, the girls’ hostel is situated adjacent to the academic building inside the school premises. Each classroom is fully air-conditioned and so designed to maintain the sanctity of teachers and students.
            </p>
            <p>
                The College is known for its commendable infrastructure and it houses several infrastructural facilities including Staff quarters, Library, playground apart from hostels, administrative and academic building. The laboratories are well equipped and adequate for the students. The Time- Table for engagement of classes and for conduct of various academic programs is adjusted in such a manner that the infrastructure is optimally used. It has also a sprawling playground catering to both indoor and outdoor games and sports requirements of the students. The College is situated at Mauli Maa Nagar, BTM Chowk with advantage of connectivity through air, road and trains. Its contiguity to the town is a distinct advantage.
            </p>
            <p>
                In the pursuit to provide perfection through holistic education, Aryabhatt Higher Secondary School was established in 2014 at Jharsuguda. The college caters to the hopes and aspirations of the vast tribal populations of the area as a Temple of learning for Higher Education. The Institution serves this backward region eagerly to keep the eternal flame of learning, knowledge & competence ablaze so that the young aspiring minds of this locality prepare themselves to serve the country at large.
            </p>
        </Container>
    )
}