import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Chairman() {
    return (
        <Container className="pt-5 allign">
            <h2 className='mb-3'>CHAIRMAN'S MESSAGE</h2>
            <p>
                Education is the most powerful weapon which you can use to change the world.
            </p>
            <p>
                Education, the most valuable asset apart from being the medium of acquiring skills and knowledge, is also the weapon for making an own-self stand in endurance. Let us be on the true way of education to acquire the precise position of existence.
            </p>
            <p>
                Magnificent careers always begin with aimed and focused mind who overcomes all the hurdles with unshaken confidence that comes in his/her way. Seven years back I visualized, molding the brilliant minds through a perfect system, on a single podium, where the 10th passed out students can avail the widest exposure to flourish their talent and subsequently excel in both academics and competitive examinations.
            </p>
            <p>
                It had always been my dream to build an institution for quality, innovation and excellence by maintaining a high standard of academic discipline and providing world class facilities for education delivery and creating technocrats with knowledge and competence. Besides technical competence, I personally feel that moral values are equally important.
            </p>
            <p>
                During the last Seven years, ARYABHATT HIGHER SECONDARY SCHOOL (AHSS) has developed very well and has been recognized as one of the Best Science Colleges in the state of Odisha. The College has well qualified, experienced and dedicated faculty and supporting staff, state of the art laboratories, workshop and computing facilities. The college has developed excellent infra-structural facilities with an enriched library, which are updated regularly with scientific journals, which helps students to understand the recent trends in Science and Technology. The College provides not only technical education to the students but also conducts many co-curricular and extra-curricular programs, which ensure multifaceted development of students. They are always encouraged to explore their ideas and their innovative skills.
            </p>
            <p>My utmost desire was to give a realistic contour to my vision, which resulted in foundation of Aryabhatt Higher Secondary School in 2014. Since inception Aryabhatt Higher Secondary School being on its full gesture, has been able to produce the jewels of education who not only topped board but also excelled the competitive examinations, making their coveted dream of becoming an engineer or a doctor come true.</p>
            <p>At Aryabhatt College, we endow with palpable competitive environment, committed faculties, efficient management staff, best infrastructure, well managed hostel facility and round the clock security system. Adding up, teachers and students are trained by the system of unique teaching methodology productive learning process correspondingly. The system laid its ultimate effort to explore full potential and boost self confidence of the students, where they assertively say “I Can”.</p>
            <p>Finally, I would like to extend my warm welcome to the students and parents to Aryabhatt College, where they can visualize the divergence, will sear out of the darkness, strengthen their constant endeavour and enjoy academic and competitive ambience under one roof. I convey my best wishes to all the students and their parents for a successful future and good education.</p>
        </Container>
    )
}