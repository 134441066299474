import { Container } from "react-bootstrap";
import "../../Component.css";

export default function Secretary() {
    return (
        <Container className="pt-5 allign">
            <h2 className='mb-3'>SECRETARY'S MESSAGE</h2>
            <p>Aryabhatt College was established with great motivation to provide quality education in Science and to produce competent professionals in the respective fields, such that its graduates not only readily acceptable by the industry but also achieve respectable places in the society as well.</p>
            <p>I am hopeful and quite sure that the college will transform its students into enlightened and dynamic young professionals ready to face the challenges of Society and Industry. Their budding talent in science will surely give additional value in the growth and prosperity of the society and nation. Continuous and sincere efforts from all sources of this institute assure the future of its disciples and also meet the expectations of their parents.</p>
            <p>We are constantly endeavoring to enrich the infrastructure through incorporating latest enhancements like audio/visual tools, modernized laboratories, personality development programs and rigorous interaction with the industry. I am sure that students will have ample learning opportunities with us, which would definitely shape their career and help them in achieving their goals. Having being enriched with the experience of observing a number of technical institutes growing from their juvenile stage to the stage of strong fruit bearing tree, I feel satisfied with the growth of Aryabhatt College. I congratulate the college for standing strong for the future of students and bringing out the best out of them. May God bless our students to achieve professional eminence in their fields of education and make our members fulfill their dreams of Centers of Excellence in professional education.</p>
        </Container>
    )
}