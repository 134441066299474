import "./Component.css";
import Slider from "./Carousel";
import CHSE from "./CHSE.jpeg";
import Nursing from "./Nursing.jpeg";
import ISO from "./ISO.jpeg";
import airtel from "./airtel.jpeg";
import jio from "./jio.jpeg";
import sIndia from "./skillIndia.jpeg";
import dIndia from "./digitalIndia.jpeg";
import TNV from "./TNV.jpeg";
import bpt from "./bpt.jpeg";
import delnet from "./delnet.jpeg";
import nats from "./nats.jpeg";
import ndli from "./ndli.jpeg";
import nptel from "./nptel.jpeg";
import swyam from "./swyam.jpeg";
import ACHMTSW from "./ACHMTSW.png";
import AHSS from "./AHSS.png";
import AIMS from "./AIMS.png";
import APC from "./APC.png";
import shriram from "./shriram.jpeg";
import lal from "./lal.jpeg";
import yasoda from "./yasoda.jpeg";
import sevenhill from "./sevenhills.jpeg";
import reddy from "./reddy.jpeg";
import apollo from "./apollo.jpeg";
import { Col, Container, Row, Carousel, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import fire from "../firebase";
import "firebase/firestore";
import { useEffect, useState } from "react";

export default function Home() {
    window.scrollTo(0,0);
    const db = fire.firestore();

    const [info,setInfo] = useState([])
    const [anndata, setAnnData] = useState();

    useEffect( () => {
        db.collection('News').orderBy('time', 'desc').onSnapshot(snap => {
            setInfo(snap.docs.map(doc => ({
                id : doc.id,
                infodata : doc.data()
            })))
        })
    },[db]);

    db.collection("Announcement").doc('ann').get().then( i => {
        setAnnData(i.data().content)
    })

    return (
        <div className="Home">
            <Slider />
            <div className="announcement">
                <h6 className="head">Announcement</h6>
                <p className="notify">
                    {anndata}
                </p>
            </div>

            <Container className="mt-3">
                <Row>
                    <Col md={6} className="mt-2">
                        <h3 className="text-center p-2 m-0" style={{background: "rgb(0,255,0)"}}>Eminent Personalities @ Aryabhatt</h3>
                    <Carousel>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Eminent%20Personalities%2FAnkit%20Ray.jpeg?alt=media&token=513c9d8d-d80a-4f5f-8b1c-6f85da27999e"
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Eminent%20Personalities%2FLaxmi%20Narayan%20Patel.jpg?alt=media&token=16167737-0621-4b8b-ad62-fc4c28a8debe"
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Eminent%20Personalities%2FSudhanshu%20Behera.jpg?alt=media&token=f619487f-46bf-44cb-9554-196a5673f7d6"
                            alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Eminent%20Personalities%2FSanjulata%20Naik.jpg?alt=media&token=69550de4-e479-4cc4-b48c-350ef60e5d1b"
                            alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                    </Col>
                    <Col md={6} className="mt-2">
                        <h3 className="text-center p-2" style={{background: "rgb(0,255,0)"}}>Info / News & Events / Placement News</h3>
                        <div className="News">
                            {info.map( i => (
                                <a href={i.infodata.contentURL} target="_blank" rel="noreferrer">
                                <Breadcrumb key={i.id}>
                                    <Breadcrumb.Item active><span style={{color: "red", fontWeight: "700"}}>{ i.infodata.date }</span></Breadcrumb.Item>
                                    <Breadcrumb.Item active><span style={{color: "blue", fontWeight: "700"}}>{ i.infodata.title} :-- </span></Breadcrumb.Item> <br/>
                                    <Breadcrumb.Item active><span style={{color: "black", fontWeight: "400"}}>{ i.infodata.content }</span></Breadcrumb.Item>
                                </Breadcrumb></a>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className="mt-5 pt-4 pb-4" style={{background: "rgb(183, 171, 250)"}}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <h4 style={{fontWeight:700}}>Vision <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg></h4>
                            <p style={{fontSize:"1.1rem",textAlign: "justify"}}>
                                To provide students with an environment for all round development of their mental, physical, aesthetic, social and spiritual potentials and generating attitude of integrity, honesty, fairness and tolerance, so that they give off their very best. Excellence in these areas is to be interpreted in terms of putting the skills developed in each to equip themselves for good career prospects and at the same time, remain at the service of the socially discriminated groups in our country, with a view to setting up a society where all have equal opportunities. The college aims for excellence in education and research in each of its disciplines, for contributing to the economic and intellectual growth of the region and state, and for educating students who are committed to excellence, leadership, service and lifelong learning. 
                            </p>
                            <h4 style={{fontWeight:700}}>Mission <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.07 4.93l-1.41 1.41C19.1 7.79 20 9.79 20 12c0 4.42-3.58 8-8 8s-8-3.58-8-8c0-4.08 3.05-7.44 7-7.93v2.02C8.16 6.57 6 9.03 6 12c0 3.31 2.69 6 6 6s6-2.69 6-6c0-1.66-.67-3.16-1.76-4.24l-1.41 1.41C15.55 9.9 16 10.9 16 12c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.86 1.28-3.41 3-3.86v2.14c-.6.35-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72V2h-1C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-2.76-1.12-5.26-2.93-7.07z"/></svg></h4>
                            <ul style={{fontSize:"1.1rem",textAlign: "justify"}}>
                                <li>To achieve academic excellence and to make this premier institution a centre for promoting social development</li>
                                <li>Maintaining a high standard of integrity and performance leading to the achievement of academic and career goals</li>
                                <li>To provide affordable quality education </li>
                                <li>To inculcate values, identify hidden talents, provide opportunities for students to realize their full potential and thus shape them into future leaders, entrepreneurs and above all good human beings</li>
                                <li>Enlightening young minds through pursuit of excellence</li>
                                <li>Introducing student centric & innovative practices in teaching-learning process</li>
                                <li>Providing best facilities like financial and moral support to economically backward class</li>
                            </ul>
                        </Col>
                        <Col md={6}>
                            <h4 style={{fontWeight:700}} className="text-center">Accreditation & Affiliation</h4>
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <img src={ISO} height="150px" alt="iso" />
                                <img src={CHSE} height="150px"  alt="chse" />
                                <img src={Nursing} height="150px" alt="Nursing" />
                            </div>
                            <h4 style={{fontWeight:700}} className="text-center mt-5">Membership</h4>
                            <div>
                                <Row className="mt-5 mb-5" style={{display: 'flex', justifyContent: "center"}}>
                                    <Col xs={2}><img src={TNV} height="80px" alt="TNV" /></Col>
                                    <Col xs={2}><img src={jio} height="80px" alt="JIO" /></Col>
                                    <Col xs={2}><img src={sIndia} height="80px" alt="skill India" /></Col>
                                    <Col xs={2}><img src={airtel} height="80px" alt="Airtel" /></Col>
                                    <Col xs={2}><img src={dIndia} height="80px" alt="Digital India" /></Col>
                                </Row>
                            </div>
                            <h4 style={{fontWeight:700}} className="text-center mt-5">Our Colleges</h4>
                            <div>
                                <Row className="mt-5 mb-3" style={{display: 'flex', justifyContent: "space-around"}}>
                                    <img src={AHSS} height="80px" alt="AHSS" />
                                    <img src={AIMS} height="80px" alt="AIMS" />
                                    <img src={ACHMTSW} height="80px" alt="ACHMTSW" />
                                    <img src={APC} height="80px" alt="APC" />
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>  
            </div>

            <div className="mt-2 text-center" style={{width: "100%"}}>
                <a className="m-auto" rel="noreferrer" href="https://onlinecourses.nptel.ac.in/" target="_blank"><img height="40px" src={nptel} alt="nptel" /></a>
                <a className="m-auto" rel="noreferrer" href="http://www.bopter.gov.in/" target="_blank"><img height="40px" src={bpt} alt="bpt" /></a>
                <a className="m-auto" rel="noreferrer" href="http://mhrdnats.gov.in/" target="_blank"><img height="40px" src={nats} alt="nats" /></a>
                <a className="m-auto" rel="noreferrer" href="https://swayam.gov.in/" target="_blank"><img height="40px" src={swyam} alt="swyam" /></a>
                <a className="m-auto" rel="noreferrer" href="https://ndl.iitkgp.ac.in/" target="_blank"><img height="40px" src={ndli} alt="ndli" /></a>
                <a className="m-auto" rel="noreferrer" href="http://delnet.nic.in/" target="_blank"><img height="40px" src={delnet} alt="delnet" /></a>
            </div>
            {/* <Row className="mt-2" style={{width: "100%"}}>
                <Col md={3} lg={2} className="m-auto"><a rel="noreferrer" href="https://onlinecourses.nptel.ac.in/" target="_blank"><img height="40px" src={nptel} alt="nptel" /></a></Col>
                <Col md={3} lg={2} className="m-auto"><a rel="noreferrer" href="http://www.bopter.gov.in/" target="_blank"><img height="40px" src={bpt} alt="bpt" /></a></Col>
                <Col md={3} lg={2} className="m-auto"><a rel="noreferrer" href="http://mhrdnats.gov.in/" target="_blank"><img height="40px" src={nats} alt="nats" /></a></Col>
                <Col md={3} lg={2} className="m-auto"><a rel="noreferrer" href="https://swayam.gov.in/" target="_blank"><img height="40px" src={swyam} alt="swyam" /></a></Col>
                <Col md={3} lg={2} className="m-auto"><a rel="noreferrer" href="https://ndl.iitkgp.ac.in/" target="_blank"><img height="40px" src={ndli} alt="ndli" /></a></Col>
                <Col md={3} lg={2} className="m-auto"><a rel="noreferrer" href="http://delnet.nic.in/" target="_blank"><img height="40px" src={delnet} alt="delnet" /></a></Col>
            </Row> */}

             <Container className="mt-5">
                <Row>
                    <Col lg={4}>
                        <h4 className="text-center">Programs</h4>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/+2-Science" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '15px'}}>+2 Science</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/DMLT" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '15px'}}>DMLT</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/D.Pharma" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '15px'}}>D.Pharma</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/ANM" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '15px'}}>ANM</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/GNM" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '15px'}}>GNM</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/B.Sc-Nursing" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '15px'}}>B.Sc Nursing</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/Hotel-Management&Tourism" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '15px'}}>Hotel Management & Tourism</li></Link>
                        </ul>
                    </Col>
                    <Col lg={4}>
                        <h4 className="text-center" style={{color: 'darkblue'}}>TESTIMONIAL</h4>
                        <Carousel>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Testimonials%2FtestimonialTwo.jpeg?alt=media&token=a6a9bfa7-d3ab-4f00-a79b-4e1b0271bf18"
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Testimonials%2FtestimonialOne.jpeg?alt=media&token=6317ec5f-a31c-40e3-81c4-3cfdb712d7c5"
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Testimonials%2FShiksha%20Singh.jpg?alt=media&token=319225c3-d21a-42f7-87fb-ae6b9d78c452"
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Testimonials%2FSandeep%20Naik.jpg?alt=media&token=85a6b4ca-3eef-4bcc-a5bd-c4dc69a89b4b"
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Testimonials%2FSilu%20Meher.jpg?alt=media&token=240202d9-893a-496e-bcac-f9e96c9e10ef"
                            alt="Second slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                    </Col>
                    <Col lg={4}>
                        <h4 className="text-center">Campus Video</h4>
                        <iframe width="100%" height="250px" src="https://www.youtube.com/embed/R1MuGg2jZv0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Col>
                </Row>
            </Container>

            <div className="text-center mt-5">
                <h2>OUR RECRUITERS</h2>
                <img width="100px" className="m-3" src={apollo} alt='our recruiters' />
                <img width="100px" className="m-3" src={lal} alt='our recruiters' />
                <img width="100px" className="m-3" src={reddy} alt='our recruiters' />
                <img width="100px" className="m-3" src={sevenhill} alt='our recruiters' />
                <img width="100px" className="m-3" src={shriram} alt='our recruiters' />
                <img width="100px" className="m-3" src={yasoda} alt='our recruiters' />
            </div>

            <Container className="mt-5">
                <Row>
                    <Col md={4}>
                        <h4 className="text-center">PLACEMENT CELL</h4>
                        <img className="mt-3 mb-3" src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/collegeImages%2FPlacementCell.jpeg?alt=media&token=0a376cc6-5b67-4c3e-b0a5-ad7ccaa9fe27" width="100%" alt="placement" />
                        <p className="allign">Campus recruitment for final year students starts from June onwards every year. Recruitment after passing out of the campus will also be done depending on the availability of non-placed students. Interested recruiters are requested to mail the following details to <a href="mailto: recruitment@aryabhattcollege.org.in">recruitment@ aryabhattcollege.org.in</a></p>
                        <h6><Link to="Placement-Report">Read More {">>"}</Link></h6>
                    </Col>
                    <Col md={4}>
                        <h4 className="text-center">ADMISSION</h4>
                        <img className="mt-3 mb-3" src="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/collegeImages%2FAdmission.jpeg?alt=media&token=c041f25b-39ef-402e-86bc-22738f038175" width="100%" alt="admission" />
                        <p className="allign">Our mission is to enable, encourage and enrich each and every student to realize his/her dreams to be a best academician, Doctor or an Engineer. Effective Education trains our students during these two months to excel in CHSE as well as competitive examinations, by clearing their fundamentals so that a student can start a colorful beginning to higher secondary syllabus.</p>
                        <h6><Link to="Admission-Procedure">Read More {">>"}</Link></h6>
                    </Col>
                    <Col md={4}>
                        <h4 className="text-center mb-4">QUICK LINKS</h4>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/The-Institute" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '10px 15px'}}>The Institute</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/Admission-Procedure" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '10px 15px'}}>Admission Procedure</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <a target="_blank" rel="noreferrer" href="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Fee%20Structure%2FFee%20Structure%20(1).pdf?alt=media&token=b483e3f4-7368-4517-b6e3-ce6ccbe204a4" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '10px 15px'}}>Fee Structure</li></a>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/Facilities" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '10px 15px'}}>Facilities</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <Link to="/Education-Loan" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '10px 15px'}}>Education Loan</li></Link>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <a target="_blank" rel="noreferrer" href="https://scholarship.odisha.gov.in/website/home" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '10px 15px'}}>Student Scholarship</li></a>
                        </ul>
                        <ul style={{listStyle: "none", background: "rgb(0,255,0)",borderRadius: '10px'}}>
                            <a target="_blank" rel="noreferrer" href="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Brochure%2FBrochure.pdf?alt=media&token=17c38156-a726-414a-8d5f-3c2a8768d3a3" style={{textDecoration: "none", color: "black",fontWeight: "700"}}><li style={{padding: '10px 15px'}}>e-Brochure</li></a>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}