import "./Component.css";
import CollegeLogo from "./collegeLogo.jpeg";
import logoTwo from "./logo_two.jpg";
import { Navbar, Nav, NavDropdown, Button, Container, Row, Col } from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Navigation() {
    const logoIcons = {
        home : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>,
        events : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/></svg>,
        school : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/></svg>,
        calendar : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-5-5H7v2h7v-2z"/></svg>,
        login : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
    }
    return (
        <>
            <Navbar bg="light" variant="light" expand="md" className="justify-content-between" collapseOnSelect>
                <Navbar.Text>
                <Button style={{background: "rgb(0,255,0)", border: "none",fontWeight: "600"}}>
                    <a href="tel: 6372920187" style={{textDecoration: "none", color: "black"}}><svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="30px" fill="orangered"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg> 6372920187/</a>
                    <a href="tel: 6372889687" style={{textDecoration: "none", color: "black"}}> 6372889687</a>
                </Button>
                </Navbar.Text>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="https://pmny.in/rr72uDyAL2Pu" target="_blank"><Button style={{background: "rgb(0,255,0)",color: "black", border: "none",fontWeight: "600"}}><span style={{fontWeight : '700'}}>₹</span> Pay Online</Button></Nav.Link>
                        <Nav.Link as={Link} to="/Admission-Enquiry"><Button style={{background: "rgb(0,255,0)",color: "black",border: "none",fontWeight: "600"}}>{logoIcons.school} Admission Enquiry</Button></Nav.Link>
                        <Nav.Link target="_black" href="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Brochure%2FAnti%20Ragging%20Policy.pdf?alt=media&token=3189e02c-ebf2-4fb9-b1d2-baad5edbf450"><Button style={{background: "rgb(0,255,0)",color: "black",border: "none",fontWeight: "600"}}>{logoIcons.events} Anti Ragging Policy</Button></Nav.Link>
                        <Nav.Link as={Link} to="/Placement"><Button style={{background: "rgb(0,255,0)",color: "black",border: "none",fontWeight: "600"}}>{logoIcons.events} Placement</Button></Nav.Link>
                        <Nav.Link as={Link} to="/Career"><Button style={{background: "rgb(0,255,0)",color: "black",border: "none",fontWeight: "600"}}>{logoIcons.calendar} Career</Button></Nav.Link>
                        <Nav.Link as={Link} to="/Admin"><Button style={{background: "rgb(0,255,0)",color: "black",border: "none",fontWeight: "600"}}>{logoIcons.login} Login</Button></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Container>
                <Row>
                    <Col md={6}><Link to="/"><img src={CollegeLogo} width="100%" alt="college logo" /></Link></Col>
                    <Col md={6} className="toRight"><img src={logoTwo} width="50%" alt="college logo" /></Col>
                </Row>
            </Container>
            

            <Navbar collapseOnSelect style={{background: "rgb(0, 255, 0)", fontWeight: '700', fontSize: "1.1rem"}} expand="lg" sticky="top">
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="ml-auto" style={{maxHeight: "170px"}} navbarScroll>
                        <Nav.Link as={Link} to="/">{logoIcons.home} HOME</Nav.Link>
                        <NavDropdown title="ABOUT" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/The-Institute">The Institute</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Chairman's-Message">Chairman's Message</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Secretary's-Message">Secretary's Message</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/President's-Message">President's Message</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Principal's-Message">Principal's Message</NavDropdown.Item>
                            <NavDropdown.Item target="_blank" href="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Brochure%2FAccreditation%20%26%20Affiliation.pdf?alt=media&token=54769d91-5403-4c47-afca-6d9f20fa1fca">Accreditation & Affiliation</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="ACADEMICS" id="basic-nav-dropdown">
                            <NavDropdown title="Course" id="basic-nav-dropdown" className="pl-3" drop="right">
                                <NavDropdown.Item as={Link} to="/+2-Science">+2 Science</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/DMLT">DMLT</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/D.Pharma">D.Pharma</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/ANM">ANM</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/GNM">GNM</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/B.Sc-Nursing">B.Sc Nursing</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/Hotel-Management&Tourism">Hotel Management & Tourism</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown.Item as={Link} to="/CHSE-Pattern">CHSE Pattern</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Teaching-Methodology">Teaching Methodology</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Our-Boosting-Strategy">Our Boosting Strategy</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="ADMISSION" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/Admission-Procedure">Admission Procedure</NavDropdown.Item>
                            <NavDropdown.Item href="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Fee%20Structure%2FFee%20Structure%20(1).pdf?alt=media&token=b483e3f4-7368-4517-b6e3-ce6ccbe204a4" target="_black">Fee Structure</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Facilities">Facilities</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Education-Loan">Education Loan</NavDropdown.Item>
                            <NavDropdown.Item target="_blank" href="https://scholarship.odisha.gov.in/website/home">Student Scholarship</NavDropdown.Item>
                            <NavDropdown.Item target="_blank" href="https://firebasestorage.googleapis.com/v0/b/aryabhatt-college.appspot.com/o/Brochure%2FBrochure.pdf?alt=media&token=17c38156-a726-414a-8d5f-3c2a8768d3a3">e-Brochure</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="EXAMINATION" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/Examination">Examination</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Result">Result</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="T & P" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/Training&Placement-Department">Training & Placement Department</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Procedure">Procedure</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Training-Program">Training Program</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Placement-Report">Placement Report</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Our-Recruiters">Our Recruiters</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/Alumni">ALUMNI</Nav.Link>
                        <Nav.Link as={Link} to="/Gallery">GALLERY</Nav.Link>
                        <Nav.Link as={Link} to="/Contact-Us" className="mr-5">CONTACT</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}