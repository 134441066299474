import { Container } from "react-bootstrap";
import "../../Component.css";

export default function TPD() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h2>About Training & Placement Department</h2>
            <p className="mt-5">The Training & Placement Department of Aryabhatt College has been in the forefront of activities, bringing corporate close to the campus and encouraging them to establish facilities to cater to the needs of the students. It plays a pivotal role in counselling and guiding the students for their successful career, which is a crucial interface between the stages of completion of academic program of the students and their entry into the job market. Its aim is to provide career guidance and placement opportunities to all students studying in Aryabhatt College. Training and Placement Department arranges and coordinates various programs that aim at moulding the students so as to meet the industry expectations in their career building and in turn bring laurels to the parent institution. The department sincerely believes in imparting comprehensive training to the students to face the challenges of the dynamic and demanding job market and build a successful career for themselves. Special SKILL ENHANCEMENT TRAINING PROGRAMS have been customized for students so as to meet the industry requirement.</p>

            <h4>Department Vision</h4>
            <p>“To consistently provide assistance and guidance to multi-talented technically competent young professionals blended with positive approach and ethical values who can effectively shoulder the task of building a vibrant India.”</p>

            <h4>Department Mission</h4>
            <p>“To work as a unified team and train the students for industry needs and professional skills so as to enable them to achieve their professional goals”.</p>

            <h4>Departmental Roles And Responsibilities</h4>
            <p>The Training and Placement Department, guided by a set of rules and principles, strives to maintain good relationship with industries. The department endeavors to successfully carry out all the processes related to training, placement and career guidance methodically throughout the year.</p>

            <h4>Activities Carried Out By The Department</h4>
            <ul>
                <li>To establish and maintain contacts with who’s who of Industry.</li>
                <li>Train the students year-round on employability skills required in the job market. Mentor and counsel, the students throughout the year to orient and expose them towards career requirements.</li>
                <li>Give industry exposure to the students by arranging frequent industrial visits and conducting workshops and seminars by industry experts.</li>
                <li>Invite companies for Internships and Placements at Campus.</li>
                <li>Fixing up a mutually convenient date of the drive, informing students about the company and job description through various means of communication like College Website and Facebook, Emails, Bulk messages, WhatsApp groups and personal announcements.</li>
                <li>Maintaining relationship with our Alumni who are excelling in their professions and are holding responsible positions in leading companies across the globe.</li>
            </ul>

            <h4>Facilities Provided To Recruiters</h4>
            <ul>
                <li>A state-of-the-art air-conditioned auditorium with a seating capacity of over 400 students</li>
                <li>An air conditioned, multimedia equipped Interaction Hall with seating capacity of around 60 students for interacting with the students and conducting workshops/Seminars</li>
                <li>Fully equipped, TCS accredited air-conditioned computer labs for conducting online test of around 500 students at a time.</li>
                <li>A multimedia air-conditioned conference hall for conducting Group Discussions.</li>
                <li>Well-furnished and fully equipped cabins for conducting personal Interviews. High Speed Wi-Fi Internet.</li>
                <li>Arrangement for stay at Global Premium Guest House.</li>
            </ul>
        </Container>
    )
}