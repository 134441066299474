import { Container } from "react-bootstrap";
import "../../../Component.css";

export default function DMLT() {
    return (
        <Container className="mt-3">
            <h1>DMLT Admissions</h1>
            <table>
            <tr>
                <th>Category</th>
                <th>Highlights</th>
            </tr>
            <tr>
                <td>Course Name</td>
                <td>Diploma in Medical Laboratory Technology (DMLT)</td>
            </tr>
            <tr>
                <td>Duration</td>
                <td>2 Years</td>
            </tr>
            <tr>
                <td>Minimum Qualification</td>
                <td>10+2</td>
            </tr>
            <tr>
                <td>Admission process</td>
                <td>Merit/ Entrance Base</td>
            </tr>
            <tr>
                <td>Fee</td>
                <td>
                Hosteller - Rs 92,000/- (Annually)<br />
                Day Scholar - Rs 62,000/- (Annually)
                </td>
            </tr>
            </table>

            <h1>DMLT Eligibility Criteria</h1>
            <table>
            <tr>
                <th>Course Name</th>
                <th>Eligibility Criteria</th>
            </tr>
            <tr>
                <td>DMLT</td>
                <td>
                <ul>
                    <li>
                    Candidate must have at least 45-50% marks in Class 12 with
                    subjects as Physics, Chemistry and, Biology.
                    </li>
                    <li>There is no age limit for DMLT Admission in India</li>

                    <li>
                    Candidates awaiting their Higher Secondary Exam results can also
                    apply for the DMLT course.
                    </li>
                </ul>
                </td>
            </tr>
            </table>

            <h2>Documents Required for DMLT Admissions</h2>
            <p>
            Some of the important documents required for DMLT admission are as
            follows:
            </p>
            <ul>
            <li>Class 10th Certificate and Mark sheet</li>
            <li>Transfer Certificate</li>
            <li>Migration Certificate</li>
            <li>Category Certificate(If Applicable)</li>
            <li>Application Fee Receipt</li>
            </ul>
        </Container>
    )
}