import { Container } from "react-bootstrap";
import "../../Component.css";

export default function President() {
    return (
        <Container className="pt-5 allign">
            <h2 className='mb-3'>PRESIDENT'S MESSAGE</h2>
            <p>Aryabhatt College is a growing technical institution with an excellent track record of its performance over the last 07 years in the state of Odisha. The faculty and students always strive hard to maintain its position of leadership in Science.</p>
            <p>With each passing year, my belief in value based education has grown stronger. I believe that happiness is an uninterrupted activity and I am going ahead with my dreams with the wholehearted support of my ROYAL FAMILY. I have followed Mahatma Gandhi’s saying “Real education means knowledge of duty and building of character”.</p>
            <p>Our family always believes in flourishing and nurturing a supportive environment. It has bridged the gap between child’s potential and achievement. Our teachers and counselors capitalize on each child’s strength while simultaneously addressing areas of weaknesses. We have strived for a delicate balance between “addressing unique needs” and “raising the standard” for each student to achieve maximum learning. We have believed not only in physical and mental developments of our pupils, but also believe in inculcating in them with humanitarian qualities.</p>
            <p>Let it be my privilege to welcome you to the world of Aryabhatt College.</p>
        </Container>
    )
}