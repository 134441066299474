import { Container } from "react-bootstrap";
import "../../../Component.css";

export default function HTM() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5 allign">
            <h1>Hotel Management Bachelor Course Details</h1>
    <table>
      <tr>
        <th>Duration</th>
        <td>3 to 4 Years</td>
      </tr>
      <tr>
        <th>Eligibility</th>
        <td>10+2 from a recognized board with a minimum aggregate score</td>
      </tr>
      <tr>
        <th>Admission Process</th>
        <td>Entrance Exams, Direct Admission</td>
      </tr>
      <tr>
        <th>Fees</th>
        <td>INR 3,00,000 - 10,00,000</td>
      </tr>
    </table>
    <h1>Bachelor’s in Hotel Management Courses Eligibility Criteria</h1>
    <ul>
      <li>
        A candidate with a merit of 50% in 10+2 is eligible for admission in
        hotel management bachelor courses either directly or via hotel
        management entrance exams.
      </li>
      <li>Merit Score can secure admissions in hotel management colleges.</li>
      <li>
        The majority of bachelor courses are offered through entrance exams,
        which include questions from the hospitality sector, and numerical and
        reading ability.
      </li>
      <li>
        AIMA UGAT, NCHMCT JEE are some of the entrance exams that offer
        admissions to all the bachelor hotel management courses in India, while
        other state-level exams are also there.
      </li>
    </ul>
    <h2>Documents Required for Bachelor in Hotel Management Admissions:</h2>
    <p>
      Some of the important documents required for Bachelor in Hotel Management
      admissions are as follows:
    </p>
    <ul>
      <li>Class 10th Certificate and Mark sheet</li>
      <li>+2 Certificate &amp; Mark Sheet</li>
      <li>Transfer Certificate</li>
      <li>Migration Certificate</li>
      <li>Category Certificate (If Applicable)</li>
      <li>Application Fee Receipt</li>
    </ul>
        </Container>
    )
}