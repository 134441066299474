import { Container } from "react-bootstrap";
import "../../../Component.css";

export default function Science() {
    window.scrollTo(0,0);
    return (
        <Container className="mt-5" style={{overflow: "scroll"}}>
            <h2>+2 SCIENCE ADMISSIONS</h2>
            <table>
                <tr>
                    <th>Category</th>
                    <th>Highlights</th>
                </tr>
                <tr>
                    <th>Course Name</th>
                    <td>+2 Science</td>
                </tr>
                <tr>
                    <th>Duration</th>
                    <td>2 Years</td>
                </tr>
                <tr>
                    <th>Minimum Qualification</th>
                    <td>10th</td>
                </tr>
                <tr>
                    <th>Admission Process</th>
                    <td>Merit/Entrance Base</td>
                </tr>
                <tr>
                    <th>Fee</th>
                    <td>
                    <table>
                        <tr>
                        <th>Hostel</th>
                        <th></th>
                        <th>Day Scholar</th>
                        </tr>
                        <tr>
                        <th>1st Year</th>
                        <th>2nd Year</th>
                        <th>1st Year</th>
                        <th>2nd Year</th>
                        </tr>
                        <tr>
                        <td>₹ 1,12,000/-</td>
                        <td>₹ 1,16,000/-</td>
                        <td>₹ 82,000/-</td>
                        <td>₹ 86,000/-</td>
                        </tr>
                    </table>
                </td>
                </tr>
            </table>

            <h2>Documents Required for +2 Science Admissions:</h2>
            <ul>
                <li>A Latest Passport size photo</li>
                <li>Matric Board Certificate</li>
                <li>School Leaving Certificate</li>
                <li>Conduct Certificate</li>
                <li>An Email Id</li>
                <li>A mobile number</li>
                <li>Aadhar Card</li>
                <li>Bank Passbook</li>
                <li>Required fees</li>
            </ul>
            <h2>Fees for Apply Odisha +2 Admission 2021</h2>
            <ul>
                <li>For General applicants- 200/- per CAF</li>
                <li>For SC/ST Category applicants-100/- per CAF</li>
                <li>No fees for Disability persons (more than 40%), Orphan, and Transgender applicants.</li>
            </ul>
        </Container>
    )
}