import { Container } from "react-bootstrap";
import "../../../Component.css";

export default function Nursing() {
    return (
        <Container className="mt-3">
            <h1>B.SC. NURSING Admissions</h1>
            <table>
            <tr>
                <th>Category</th>
                <th>Highlights</th>
            </tr>
            <tr>
                <td>Course Name</td>
                <td>Bachelor of Science in Nursing(B. SC. NURSING)</td>
            </tr>
            <tr>
                <td>Duration</td>
                <td>4 Years</td>
            </tr>
            <tr>
                <td>Minimum Qualification</td>
                <td>10+2</td>
            </tr>
            <tr>
                <td>Admission process</td>
                <td>Merit/ Entrance Base</td>
            </tr>
            <tr>
                <td>Fee</td>
                <td>
                Hosteller - Rs 1,28,000/- (Annually)<br />
                Day Scholar - Rs 98,000/- (Annually)
                </td>
            </tr>
            </table>

            <h1>B.SC. NURSING Eligibility Criteria</h1>
            <table>
            <tr>
                <th>Course Name</th>
                <th>Eligibility Criteria</th>
            </tr>
            <tr>
                <td>B. SC. NURSING</td>
                <td>
                <ul>
                    <li>
                    <span>BSc (Basic)</span>- Candidates should pass 10+2 in Physics,
                    Chemistry and Biology and should be medically fit.
                    </li>
                    <li>
                    <span>BSc (Post-Basic)</span>- Candidates should pass in
                    PCB(Physics,Chemistry and Biology). One has to get a certificate
                    in GNM(General Nursing Midwifery) and register him/herself as RNRM
                    (Registered Nurse Registered Midwife) from the State Nurses
                    Registration Council.
                    </li>
                </ul>
                </td>
            </tr>
            </table>

            <h2>Documents Required for B.SC. NURSING Admissions</h2>
            <p>
            Some of the important documents required for B.SC. NURSING admissions are
            as follows:
            </p>
            <ul>
            <li>Class 10th Certificate and Mark sheet</li>
            <li>+2 Certificate & Mark Sheet</li>
            <li>Transfer Certificate</li>
            <li>Migration Certificate</li>
            <li>Category Certificate(If Applicable)</li>
            <li>Application Fee Receipt</li>
            </ul>
        </Container>
    )
}